import React from "react"; 
import './Footer.css';

function Footer () {

    return (
        <div>
            Footer
        </div>
    )
}

export default Footer;