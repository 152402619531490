import GalleryImages from "./GalleryImages";
import { Container } from "@mui/material";
import "./GalleryRight.css";

function GalleryRight({ imageData, projectStatement }) {
  return (
    <div className="gallery-right">
      <div className="gallery-right-content">
        <p>{projectStatement}</p>
      </div>
      <div className="gallery-right-gallery">
        <Container maxWidth="md" sx={{ textAlign: "center", mt: "3rem" }}>
          <GalleryImages imageData={imageData} />
        </Container>
      </div>
    </div>
  );
}

export default GalleryRight;
