import React from 'react';
import './Cta.css';

function Cta () {

    return (
        <div>
            CTA
        </div>
    )
}

export default Cta