import HeaderFilm from "../../containers/headerFilm/HeaderFilm";
import Scripts from "../../containers/scripts/Scripts";
import GalleryRight from "../../containers/gallery/GalleryRight";
import GalleryLeft from "../../containers/gallery/GalleryLeft";
import GalleryCentreBlack from "../../containers/gallery/GalleryCentreBlack";

import "./FilmProject.css";

function FilmProject({
  projectTitle,
  projectLogline,
  projectSummary,
  posterImage,
  embedId,
  stillsImages,
  btsImages,
  marketingImages,
  projectStatement,
}) {
  return (
    <div>
      <HeaderFilm
        projectTitle={projectTitle}
        projectLogline={projectLogline}
        projectSummary={projectSummary}
        posterImage={posterImage}
        embedId={embedId}
      />
      <div className="film-project-page">
        <div>
          <h2>MOVIE STILLS</h2>
          <GalleryRight
            imageData={stillsImages}
            projectStatement={projectStatement}
          />
        </div>
        <div>
          <div className="gallery-bts">
            <h2>BEHIND THE SCENES</h2>
            <GalleryCentreBlack imageData={btsImages} />
          </div>
        </div>
        <div>
          <div className="gallery-marketing">
            <h2>MARKETING</h2>
            <GalleryLeft imageData={marketingImages} />
          </div>
          <div>
            <Scripts />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilmProject;
