import Header from "../../containers/header/Header";
import Latest from "../../containers/latest/Latest";
import ScriptLibrary from "../../containers/scriptlibrary/ScriptLibrary";
import Scripts from "../../containers/scripts/Scripts";
import Contact from "../../containers/contact/Contact";
import Cta from "../../components/cta/Cta";
import HeaderImage from "../../assets/images/Helen-Black-Jitter.gif";

const homeHeaderText =
  "A female writer-director with a distinctive storytelling voice. Interested in big stories set in small worlds, I gravitate towards projects that are dark and comedic in tone with an absurdist edge, and I am particularly a fan of dark suburbia movies. As a writer / director, I revel in making the mundane magnificent, the banal exciting, utilising heightened, stylised dialogue to achieve this.";
const homeHeaderTitle = "HELEN GADEN \n WRITER DIRECTOR";

const instaLink = "https://www.instagram.com/helen.l.gaden/";

function Home() {
  return (
    <div>
      <Header
        headerText={homeHeaderText}
        headerTitle={homeHeaderTitle}
        headerImage={HeaderImage}
        instaLink={instaLink}
      />
      <Latest />
      <ScriptLibrary />
      <Scripts />
      <Contact />
      <Cta />
    </div>
  );
}

export default Home;
