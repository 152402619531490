import React from "react"; 
import './ScriptLibrary.css';

function ScriptLibrary () {

    return (
        <div className="home__scriptLibrary">
            <div className="home__scriptLibrary-header">
                <div className="home__scriptLibrary-container">
                    <div className="home__scriptLibrary-content">
                        <h3>ALL SCRIPTS</h3>
                        <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum </p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ScriptLibrary;