import React from "react";
import "./Latest.css";
import image from "../../assets/images/Both-4.png";
import { Link } from "react-router-dom";

function Latest() {
  return (
    <div className="home__latest">
      <div className="home__latest-header">
        <h2>LATEST PROJECT</h2>
      </div>
      <div className="home__latest-container">
        <div className="home__latest-image">
          <img src={image} />
        </div>
        <div className="home__latest-content slide-in-blurred-right">
          <h3>FEVER (2022) - SHORT FILM</h3>
          <p>
            A couple's relationship falls apart after the internet goes down one
            night.
          </p>
          <button type="button" className="watch-button bounce-top">
            <Link to="/fever">EXPLORE</Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Latest;
