import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./ContactForm.css";

// import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function ContactForm() {
  const [formMode, setFormMode] = useState(false);

  const contactForm = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_b048tbq",
        "template_upspajh",
        contactForm.current,
        "RNDoElib8j_D5gt-k"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact-form-container">
      {!formMode ? (
        <div className="contact-form-header">SEND ENQUIRY</div>
      ) : (
        <div className="contact-form-header">REQUEST A SCRIPT</div>
      )}
      <div className="contact-form">
        <div className="left-of-form">
          {!formMode ? (
            <button
              className="contact-form-button"
              onClick={() => setFormMode(!formMode)}
            >
              REQUEST SCRIPT
            </button>
          ) : (
            <button
              className="contact-form-button"
              onClick={() => setFormMode(!formMode)}
            >
              MAKE ENQUIRY
            </button>
          )}
        </div>
        <form ref={contactForm} onSubmit={sendEmail} className="right-of-form">
          <div className="contact-form-section">
            <label>What's Your Name?</label>
            <input
              type="text"
              name="sender_name"
              className="contact-form-input"
            />
          </div>
          <div className="contact-form-section">
            <label>What's Your Email?</label>
            <input
              type="email"
              name="sender_email"
              className="contact-form-input"
            />
          </div>
          <div className="contact-form-section">
            <label>What Company Do You Work For?</label>
            <input
              type="text"
              name="sender_company"
              className="contact-form-input"
            />
          </div>
          <div className="contact-form-section">
            <label>What's Your Position?'</label>
            <input
              type="text"
              name="sender_position"
              className="contact-form-input"
            />
          </div>
          {formMode && (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Script One"
              />
              <FormControlLabel
                // disabled
                control={<Checkbox />}
                label="Script Two"
              />
            </FormGroup>
          )}
          <div className="contact-form-section">
            <label>Write Your Message Here</label>
            <textarea
              name="sender_message"
              className="contact-form-message-area"
            />
          </div>
          <input type="submit" value="SEND" className="contact-form-button" />
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
