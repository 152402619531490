import React from "react";
import PropTypes from "prop-types";
import "./YouTube.css";

const YouTube = ({ embedId }) => (
  <div className="youtube-video">
    <iframe
      // width="853"
      // height="400"
      class="youtube-iframe"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

YouTube.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YouTube;
