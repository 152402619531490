import GalleryImages from "./GalleryImages";
import { Container } from "@mui/material";

import "./GalleryLeft.css";

function GalleryLeft({ imageData }) {
  return (
    <div className="gallery-left">
      <div className="gallery-left-gallery">
        <Container maxWidth="md" sx={{ textAlign: "center", mt: "3rem" }}>
          <GalleryImages imageData={imageData} />
        </Container>
      </div>
      <div className="gallery-left-content">
        <p>SOME TEXT</p>
      </div>
    </div>
  );
}

export default GalleryLeft;
