import React, { useState } from "react";
import "./Navbar.css";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import logo from "../../assets/logo/Alien-Brand-Logo-2.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";

function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const Menu = () => (
    <>
      <p>
        <a href="#Scripts">WRITING</a>
      </p>
      <p>
        <Link to="/fever">DIRECTING</Link>
      </p>
      <p>
        <Link to="/about">ABOUT ME</Link>
      </p>
      <p>
        <a href="https://www.instagram.com/helen.l.gaden/" target="_blank">
          <InstagramIcon />
        </a>
      </p>
    </>
  );

  return (
    <div className="top__navbar">
      <div className="top__navbar-links_logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="top__navbar-links">
        <div className="top__navbar-links_container">
          <Menu />
        </div>
        <div className="top__navbar-contact">
          <button type="button">
            <p>
              <Link to="/contact">CONTACT</Link>
            </p>
          </button>
        </div>
        <div className="top__navbar-menu">
          {toggleMenu ? (
            <RiCloseLine
              color="fff"
              size={27}
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <RiMenu3Line
              color="fff"
              size={27}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {/* if toggleMenu true */}
          {toggleMenu && (
            <div className="top__navbar-menu_container shadow-drop-2-center">
              <div className="top__navbar-menu_container-links">
                <Menu />
                <div className="top__navbar-menu_container-links-contact">
                  CONTACT ME
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
