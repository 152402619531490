import GalleryImages from "./GalleryImages";
import { Container } from "@mui/material";

import "./GalleryCentreBlack.css";

function GalleryCentreBlack({ imageData }) {
  return (
    <div className="gallery-centre-black">
      <div className="gallery-centre-black-gallery">
        <Container maxWidth="md" sx={{ textAlign: "center", mt: "3rem" }}>
          <GalleryImages imageData={imageData} />
        </Container>
      </div>
    </div>
  );
}

export default GalleryCentreBlack;
