import React from "react";
import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Navbar } from "./components/Index";
import { Footer } from "./containers/Index";
import Home from "../src/pages/home/Home";
import Fever from "./pages/filmProject/fever/Fever";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";

function App() {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
      </div>
      <Routes>
        <Route exact path="/fever" element={<Fever />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        {/* <Route exact path="/writing" element={<Writing />}/>
          <Route exact path="/directing" element={<Directing />} /> */}
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
