import * as React from "react";
import { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
// import { SRLWrapper } from "simple-react-lightbox";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function GalleryImages({ imageData }) {
  const [isOpen, setIsOpen] = useState(false);

  function clickHandler() {
    setIsOpen = !isOpen;
  }

  return (
    // <SRLWrapper>
    <ImageList
      variant="quilted"
      // sx={{ width: 1300, height: 870 }}
      cols={5}
      gap={14}
    >
      {imageData.map((item) => (
        <ImageListItem
          key={item.img}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
            onClick={clickHandler}
          />{" "}
        </ImageListItem>
      ))}
    </ImageList>
    // </SRLWrapper>
  );
}
