import React from "react";
import "./Header.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";

function Header({ headerText, headerTitle, headerImage, instaLink }) {
  return (
    <div className="helen__header gradient__bg" id="home">
      <div className="helen__header-content fade-in">
        <h1 className="gradient__text">{headerTitle}</h1>
        <p>{headerText}</p>
        <div className="helen__header-content__input">
          <button Link to="/about">
            <Link to="/about">MORE ABOUT ME</Link>
          </button>
          <button>
            <Link to="/contact">GET IN TOUCH</Link>
          </button>
        </div>
        <a href={instaLink} target="_blank">
          <div className="helen__header-content__social">
            <InstagramIcon alt="social" />
            <p>Follow me</p>
          </div>
        </a>
      </div>
      <div className="helen__header-image fade-in">
        <img src={headerImage} alt="Header Image" />
      </div>
    </div>
  );
}

export default Header;
