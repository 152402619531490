import React from "react";
import "./HeaderFilm.css";
import YouTube from "../../components/youtube/YouTube";

function HeaderFilm({
  projectTitle,
  projectLogline,
  projectSummary,
  posterImage,
  embedId,
}) {
  return (
    <div>
      <div className="filmProject__header-title gradient__bg">
        <h1>{projectTitle}</h1>
      </div>
      <div className="filmProject__header-main gradient__bg" id="home">
        <div className="filmProject__header-image fade-in">
          {/* <h2>Poster</h2> */}
          <img src={posterImage} alt="Header Image" />
        </div>
        <div className="filmProject__header-content">
          <div className="filmProject__header-content-text">
            <h2>Premise</h2>
            <p>{projectLogline}</p>
            <p>{projectSummary}</p>
          </div>
          <div className="filmProject__header-content-videos">
            <YouTube
              embedId={embedId}
              className="filmProject__header-video-trailer"
            />
            <div className="filmProject__header-content__social"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderFilm;
