import React from "react";
import "./Scripts.css";
import Script from "../../assets/images/Face-Illustration-RGB.jpg";

function Scripts() {
  return (
    <div className="home__scripts">
      <div className="home__scripts-header">
        <h2>FEATURED SCRIPTS</h2>
      </div>
      <div className="home__scripts-container">
        <div className="script-item">
          <div className="script-image-overlay" />
          <p className="script-image-overlay-text">Coming Soon</p>
          <img src={Script} className="script-image" />
        </div>
        <div className="script-item">
          <div className="script-image-overlay" />
          <p className="script-image-overlay-text">Coming Soon</p>
          <img src={Script} className="script-image" />
        </div>
        <div className="script-item">
          <div className="script-image-overlay" />
          <p className="script-image-overlay-text">Coming Soon</p>
          <img src={Script} className="script-image" />
        </div>
      </div>
    </div>
  );
}

export default Scripts;
