import React from "react";
import AboutBanner from "../../assets/images/Banner/Banner-White.jpg";
import "../about/About.css";

function About() {
  return (
    <div>
      <div className="about-header">
        <img src={AboutBanner} />
      </div>
    </div>
  );
}

export default About;
