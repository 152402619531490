import React from "react"; 
import './Contact.css';

function Contact () {

    return (
        <div>
            Contact
        </div>
    )
}

export default Contact;